import React, {Component} from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Clutch from "../components/clutch"
import Ajay_pic from "../images/team/Ajay.png"
import Aryan_pic from "../images/team/aryan_aj.png"
import Avneet_pic from "../images/team/Avneet.png"
import Balbir_pic from "../images/team/Balbir.png"
import Carlos_pic from "../images/team/Carlos.png"
import Charanjeev_pic from "../images/team/Charanjeev.png"
import Deep_pic from "../images/team/Deep.png"
import Dheeraj_pic from "../images/team/Dheeraj.png"
import Gaurav_pic from "../images/team/Gaurav.png"
import Girish_pic from "../images/team/Girish.png"
import Gopalsingh_pic from "../images/team/Gopal-Singh.png"
import Harleen_pic from "../images/team/Harleen.png"
import Jyoti_pic from "../images/team/Jyoti.png"
import Ketaky_pic from "../images/team/Ketaky.png"
import Kunal_pic from "../images/team/Kunal.png"
import Mannu_pic from "../images/team/Mannu.png"
import Paawan_pic from "../images/team/paawan_sharma.png"
import Pancham_pic from "../images/team/Pancham.png"
import Parth_pic from "../images/team/Parth.png"
import Rajender_pic from "../images/team/rajender_kumar.png"
import Rajiv_pic from "../images/team/Rajiv.png"
import Ravinder_pic from "../images/team/ravinder.png"
import Sarthak_pic from "../images/team/Sarthak.png"
import Shubham_pic from "../images/team/Shubham.png"
import Simoni_pic from "../images/team/simoni_patel.png"
import Sumit_pic from "../images/team/Sumit.png"
import Tapam_pic from "../images/team/Tapam.png"
import Vivek_pic from "../images/team/Vivek.png"
import Yash_pic from "../images/team/Yash.png"
import Jolly_pic from "../images/team/Jolly.png"
import Priceqa_pic from "../images/team/Prince_qa.png"
import Ritika1_pic from "../images/team/ritika1.png"
import Aamir_pic from "../images/team/aamir.png"
import Sahil_pic from "../images/team/Sahil_react.png"
import NidhiL_pic from "../images/team/nidhi_lakh.png"
import Aravind_pic from "../images/team/Aravind-B.png"
import Team_cfg from "../images/team/team_cfg.jpg"
import Team_cfg_mob from "../images/team/team_cfg_mob.png"
import Team_cfg_footer from "../images/team/team_footer.png"
import Team_cfg_footer1 from "../images/team/team_footer_mob.png"
import {Helmet} from "react-helmet"


export default class Container extends Component {

  constructor(props) {
    super(props);
    this.state={
      url: ''
    }
  }

  componentDidMount() {
    this.setState({
      url: document.location.href
    });
  }

  render() {

    return (
      <Layout>
        <Helmet>
          <title>CueForGood Team - the specialists helping businesses become a Force for Good</title>
          <meta name="description"
                content="CueForGood has been designing, building and marketing Ecommerce stores since 2005. Meet our team who transform these online stores into awesome websites." />
              <meta name="viewport" content="initial-scale=1.0, width=device-width, maximum-scale=5.0, minimum-scale=1.0" />
                <link rel="canonical" href={this.state.url}/>
                <meta property="og:site_name" content="CueForGood"/>
                <meta property="fb:app_id" content="289086684439915"/>
                <meta property="og:url" content={this.state.url}/>
                <meta property="og:title"
                      content="CueForGood Team - the specialists helping businesses become a Force for Good"/>
                <meta property="og:description"
                      content="CueForGood has been designing, building and marketing Ecommerce stores since 2005. Meet our team who transform these online stores into awesome websites."/>
                <meta property="og:image" content="https://www.CueForGood.com/images/cb.jpg"/>

                <meta name="twitter:card" content="summary_large_image"/>
                <meta name="twitter:description"
                      content="CueForGood has been designing, building and marketing Ecommerce stores since 2005. Meet our team who transform these online stores into awesome websites."/>
                <meta name="twitter:title"
                      content="CueForGood Team - the specialists helping businesses become a Force for Good"/>
                <meta name="twitter:site" content="@CueForGood"/>
                <meta name="twitter:image" content="https://www.CueForGood.com/images/cb.jpg"/>
        </Helmet>
        <section className="team-banner">
        <img className="mob-hide" src={Team_cfg} alt="CueForGood team picture" />  
        <img className="desk-hide" src={Team_cfg_mob} alt="CueForGood team picture for mobile" />
        
        
        </section>
        <section className="ser_sec_row team-page page-header">
          <div className="container">
            <div className="main-heading">
              <h2 className="heading_main">You are in Good Company</h2>
              <p>
              Meet the CueForGood team that works on amplifying the success of our ‘for purpose’ clients.
               </p>
            </div>

            <div className="team-members">
              <div className="member-sec">
                <div className="member-img">
                  <img src={Aamir_pic} alt="Aamir CueForGood" />
                </div>
                <div className="member-name">
                  <h4>Aamir Khan</h4>
                </div>
                <div className="member-pro">Jr. System Administrator</div>
              </div>
              <div className="member-sec">
                <div className="member-img">
                  <img src={Ajay_pic} alt="Ajay CueForGood" />
                </div>
                <div className="member-name">
                  <h4>Ajay Kumar</h4>
                </div>
                <div className="member-pro">Office Support</div>
              </div>
              <div className="member-sec">
                <div className="member-img">
                  <img src={Aravind_pic} alt="Aravind CueForGood" />
                </div>
                <div className="member-name">
                  <h4>Aravindkumar B</h4>
                </div>
                <div className="member-pro">Sr. Shopify Developer</div>
              </div>
              <div className="member-sec">
                <div className="member-img">
                  <img src={Aryan_pic} alt="Aryan CueForGood" />
                </div>
                <div className="member-name">
                  <h4>Aryan</h4>
                </div>
                <div className="member-pro">Office Support</div>
              </div>
              <div className="member-sec">
                <div className="member-img">
                  <img src={Avneet_pic} alt="Avneet CueForGood" />
                </div>
                <div className="member-name">
                  <h4>Avneet Jirman</h4>
                </div>
                <div className="member-pro">Co-Founder</div>
              </div>
          
              <div className="member-sec">
                <div className="member-img">
                  <img src={Balbir_pic} alt="Balbir CueForGood" />
                </div>
                <div className="member-name">
                  <h4>Balbir Singh</h4>
                </div>
                <div className="member-pro">UI/UX Designer</div>
              </div>
              <div className="member-sec">
                <div className="member-img">
                  <img src={Carlos_pic} alt="Carlos CueForGood" />
                </div>
                <div className="member-name">
                  <h4>Carlos</h4>
                </div>
                <div className="member-pro">Chief Cuddles Officer</div>
              </div>
              <div className="member-sec">
                <div className="member-img">
                  <img src={Charanjeev_pic} alt="Charan CueForGood" />
                </div>
                <div className="member-name">
                  <h4>Charanjeev Singh</h4>
                </div>
                <div className="member-pro">Digital Marketing Analyst</div>
              </div>
           
              <div className="member-sec">
                <div className="member-img">
                  <img src={Deep_pic} alt="Deep CueForGood" />
                </div>
                <div className="member-name">
                  <h4>Deep Sharma</h4>
                </div>
                <div className="member-pro">Sr. Accountant</div>
              </div>
              <div className="member-sec">
                <div className="member-img">
                  <img src={Dheeraj_pic} alt="Dheeraj CueForGood" />
                </div>
                <div className="member-name">
                  <h4>Dheeraj Kumar</h4>
                </div>
                <div className="member-pro">Linux System Administrator</div>
              </div>
              <div className="member-sec">
                <div className="member-img">
                  <img src={Gaurav_pic} alt="Gaurav CueForGood" />
                </div>
                <div className="member-name">
                  <h4>Gaurav Jafrain</h4>
                </div>
                <div className="member-pro">Team Lead - Quality Analyst</div>
              </div>
            

              <div className="member-sec">
                <div className="member-img">
                  <img src={Girish_pic} alt="Girish CueForGood" />
                </div>
                <div className="member-name">
                  <h4>Girish Tiwari</h4>
                </div>
                <div className="member-pro">
                Division Head-eCommerce Development
                </div>
              </div>
              <div className="member-sec">
                <div className="member-img">
                  <img src={Gopalsingh_pic} alt="Gopal CueForGood" />
                </div>
                <div className="member-name">
                  <h4>Gopal Singh</h4>
                </div>
                <div className="member-pro">Chef</div>
              </div>
              <div className="member-sec">
                <div className="member-img">
                  <img src={Harleen_pic} alt="Harleen CueForGood" />
                </div>
                <div className="member-name">
                  <h4>Harleen Kaur Sandhu</h4>
                </div>
                <div className="member-pro">Organic Search Strategist</div>
              </div>
              <div className="member-sec">
                <div className="member-img">
                  <img src={Jolly_pic} alt="Jolly CueForGood" />
                </div>
                <div className="member-name">
                  <h4>Jolly Sandhu</h4>
                </div>
                <div className="member-pro">Front End Developer</div>
              </div>
              <div className="member-sec">
                <div className="member-img">
                  <img src={Jyoti_pic} alt="Jyoti CueForGood" />
                </div>
                <div className="member-name">
                  <h4>Jyoti</h4>
                </div>
                <div className="member-pro">Sr. Web Developer</div>
              </div>
              <div className="member-sec">
                <div className="member-img">
                  <img src={Ketaky_pic} alt="Ketaky CueForGood" />
                </div>
                <div className="member-name">
                  <h4>Ketaky Sharma</h4>
                </div>
                <div className="member-pro">Division Head - People & Culture</div>
              </div>
              <div className="member-sec">
                <div className="member-img">
                  <img src={Kunal_pic} alt="Kunal CueForGood" />
                </div>
                <div className="member-name">
                  <h4>Kunal Mahajan</h4>
                </div>
                <div className="member-pro">Digital Advertising Specialist</div>
              </div>
              <div className="member-sec">
                <div className="member-img">
                  <img src={Mannu_pic} alt="mannu CueForGood" />
                </div>
                <div className="member-name">
                  <h4>Mannu Dhiman</h4>
                </div>
                <div className="member-pro">Organic Search Strategist</div>
              </div> 
              <div className="member-sec">
                <div className="member-img">
                  <img src={NidhiL_pic} alt="nidhi CueForGood" />
                </div>
                <div className="member-name">
                  <h4>Nidhi Lakhani</h4>
                </div>
                <div className="member-pro">Sr. Shopify Developer</div>
              </div>
              <div className="member-sec">
                <div className="member-img">
                  <img src={Paawan_pic} alt="paawan CueForGood" />
                </div>
                <div className="member-name">
                  <h4>Paawan Sharma</h4>
                </div>
                <div className="member-pro">Finance & Operations Specialist</div>
              </div>
              <div className="member-sec">
                <div className="member-img">
                  <img src={Pancham_pic} alt="Pancham CueForGood" />
                </div>
                <div className="member-name">
                  <h4>Pancham Prashar</h4>
                </div>
                <div className="member-pro">CEO, Co-Founder</div>
                <a href="https://www.linkedin.com/in/pancham/?originalSubdomain=in " target="_blank" rel="noreferrer" id="first-focus-element" className="focus-visible member-link" title="Opens in a new window" >
                <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="14" cy="14" r="14" fill="#0077B5"/>
                <path d="M10.592 9.29682C10.5918 9.82459 10.2716 10.2995 9.78242 10.4977C9.29326 10.6958 8.73282 10.5776 8.36535 10.1988C7.99789 9.81992 7.8968 9.25613 8.10974 8.77323C8.32268 8.29033 8.80714 7.98475 9.33466 8.00059C10.0353 8.02162 10.5923 8.59584 10.592 9.29682ZM10.6309 11.5523H8.03843V19.6667H10.6309V11.5523ZM14.727 11.5523H12.1475V19.6667H14.7011V15.4085C14.7011 13.0364 17.7926 12.8161 17.7926 15.4085V19.6667H20.3527V14.5271C20.3527 10.5282 15.777 10.6773 14.7011 12.6411L14.727 11.5523Z" fill="white"/>
                </svg>
                </a>
              </div>
              <div className="member-sec">
                <div className="member-img">
                  <img src={Parth_pic} alt="Parth CueForGood" />
                </div>
                <div className="member-name">
                  <h4>Parth Gautam</h4>
                </div>
                <div className="member-pro">Front End Developer</div>
              </div>
              <div className="member-sec">
                <div className="member-img">
                  <img src={Priceqa_pic} alt="Prince CueForGood" />
                </div>
                <div className="member-name">
                  <h4>Prince Sethi</h4>
                </div>
                <div className="member-pro">Jr. Quality Analyst</div>
              </div>
              <div className="member-sec">
                <div className="member-img">
                  <img src={Rajender_pic} alt="Rajender CueForGood" />
                </div>
                <div className="member-name">
                  <h4>Rajender Kumar</h4>
                </div>
                <div className="member-pro">Sr. Digital Advertising Specialist</div>
              </div>
              <div className="member-sec">
                <div className="member-img">
                  <img src={Rajiv_pic} alt="Rajiv CueForGood" />
                </div>
                <div className="member-name">
                  <h4>Rajiv Singha</h4>
                </div>
                <div className="member-pro">Division Head - Organic Search</div>
              </div>
              <div className="member-sec">
                <div className="member-img">
                  <img src={Ravinder_pic} alt="Ravinder CueForGood" />
                </div>
                <div className="member-name">
                  <h4>Ravinder Singh</h4>
                </div>
                <div className="member-pro">Front End Developer</div>
              </div>
              <div className="member-sec">
                <div className="member-img">
                  <img src={Ritika1_pic} alt="Ritika CueForGood" />
                </div>
                <div className="member-name">
                  <h4>Ritika Prashar</h4>
                </div>
                <div className="member-pro">Finance Specialist</div>
              </div>
              <div className="member-sec">
                <div className="member-img">
                  <img src={Sahil_pic} alt="Sahil CueForGood" />
                </div>
                <div className="member-name">
                  <h4>Sahil</h4>
                </div>
                <div className="member-pro">Web Developer</div>
              </div>
              <div className="member-sec">
                <div className="member-img">
                  <img src={Sarthak_pic} alt="Sarthak CueForGood" />
                </div>
                <div className="member-name">
                  <h4>Sarthak Aggarwal</h4>
                </div>
                <div className="member-pro">Co-Founder</div>
              </div>
              <div className="member-sec">
                <div className="member-img">
                  <img src={Shubham_pic} alt="Shubham CueForGood" />
                </div>
                <div className="member-name">
                  <h4>Shubham Jain</h4>
                </div>
                <div className="member-pro">Divison Head - Shopify</div>
              </div>
              <div className="member-sec">
                <div className="member-img">
                  <img src={Simoni_pic} alt="Simoni CueForGood" />
                </div>
                <div className="member-name">
                  <h4>Simoni Patel</h4>
                </div>
                <div className="member-pro">Sr. Shopify Developer</div>
              </div>
              <div className="member-sec">
                <div className="member-img">
                  <img src={Sumit_pic} alt="Sumit CueForGood" />
                </div>
                <div className="member-name">
                  <h4>Sumit Sharma</h4>
                </div>
                <div className="member-pro">Sr. Web Developer</div>
              </div>
             
              <div className="member-sec">
                <div className="member-img">
                  <img src={Tapam_pic} alt="Tapam CueForGood" />
                </div>
                <div className="member-name">
                  <h4>Tapam Jaswal</h4>
                </div>
                <div className="member-pro">Organic Search Strategist</div>
              </div>
              <div className="member-sec">
                <div className="member-img">
                  <img src={Vivek_pic} alt="Vivek CueForGood" />
                </div>
                <div className="member-name">
                  <h4>Vivek Puri</h4>
                </div>
                <div className="member-pro">Manager - Digital Advertising</div>
              </div>
              <div className="member-sec">
                <div className="member-img">
                  <img src={Yash_pic} alt="Yash CueForGood" />
                </div>
                <div className="member-name">
                  <h4>Yash Pal</h4>
                </div>
                <div className="member-pro">
                  Division Head, Digital Marketing (CSE)
                </div>
              </div>
            </div>
            
          </div>
        </section>
        <section className="team-banner team-banner-bottom">
          <img className="mob-hide" src={Team_cfg_footer} alt="CueForGood team activity pictures" />  
          <img className="desk-hide" src={Team_cfg_footer1} alt="CueForGood team events pictures" />
        
        </section>
        <section className="join-team">
          <div className="main-heading btmm-team">
              <h2 className="heading_main">Join our team</h2>
              <p>
                We’re always on the lookout for smart, purposeful, and driven people  to be part of our growing team.
                 Check out the careers page for open positions.
              </p>
              <Link to="/career/" className="simple_anchor btn">
              Open Positions 
              <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M4.66667 2V3.33333H1.33333V10.6667H8.66667V7.33333H10V11.3333C10 11.7015 9.70153 12 9.33333 12H0.666667C0.29848 12 0 11.7015 0 11.3333V2.66667C0 2.29848 0.29848 2 0.666667 2H4.66667ZM12 0V5.33333H10.6667L10.6666 2.27533L5.4714 7.4714L4.52859 6.5286L9.72327 1.33333H6.66667V0H12Z" fill="white"/>
              </svg>
              </Link> 
            </div>
          </section>
        <Clutch />
      </Layout>
    )

  }
}

